import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../components/common/Button";
import history from "../utils/history";
import { selectHasCompletedTourGuide } from "../redux/ui/selectors";
import { fetchTourGuideStatus } from "../redux/ui/slice";
import { selectIsAuthLoading } from "../redux/auth/selectors";

import "./home.scss";
import { LandingPageContainer } from "../components/LandingPageContainer";
import { getIsIOSDevice } from "../utils/navigator";

const EmailContact = "pearl@thepearl.xyz";
const buttonIMessageText = "text with pearl";
const buttonChatText = "speak with pearl";
const buttonContactText = "contact";
const disclaimerText = "Our app provides educational and informational content only. It is not intended to be a substitute for consultation, diagnosis, treatment or other professional medical advice from a healthcare professional, nor is it designed to provide you with a medical diagnosis, treatment or other medical services.";

const Home = () => {
  document.body.classList.remove("dark-theme");

  const dispatch = useDispatch();

  const isAuthLoading = useSelector(selectIsAuthLoading);
  const hasCompletedTourGuide = useSelector(selectHasCompletedTourGuide);

  const isIOSDevice = getIsIOSDevice();

  const handleOnChatClicked = () => {
    if (hasCompletedTourGuide) {
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((mediaStream) => {
          mediaStream.getTracks().forEach((track) => track.stop());
          history.push("/chat");
        })
        .catch(() => {});

      return;
    }

    history.push("/tour-guide");
  };

  const handleOnContactClicked = () => {
    window.location.href = `mailto:${EmailContact}`;
  };

  const handleOnIMessageClicked = () => {
    history.push("/imessage-redirect");
  }

  useEffect(() => {
    if(!isAuthLoading) {
      dispatch(fetchTourGuideStatus());
    }
  }, [dispatch, isAuthLoading]);

  // const { logout } = useAuth0();
  // const handleOnSignOutClicked = () => {
  //   logout({
  //     logoutParams: {
  //       returnTo: window.location.origin
  //     }
  //   });
  // };

  return (
    <LandingPageContainer>
      <div className="home__button-section">
        <div className="home__button">
          <Button
            variant="secondary"
            showBorder={true}
            onClick={handleOnChatClicked}
          >
            {buttonChatText}
          </Button>
        </div>
        {/* {isIOSDevice && (
          <div className="home__button">
            <Button
              variant="secondary"
              showBorder={true}
              onClick={handleOnIMessageClicked}
            >
              {buttonIMessageText}
            </Button>
          </div>
        )} */}
      </div>
      <div className="home__contact-container">
        <Button
          variant="primary"
          showBorder={false}
          onClick={handleOnContactClicked}
        >
          {buttonContactText}
        </Button>
        <div className="home__disclaimer-text">
          {disclaimerText}
        </div>
      </div>
    </LandingPageContainer>
  );
};

export default Home;
