import React from 'react';
import { Col } from "reactstrap";

import animusePearl from "../../assets/animusePearl.png";

import './landingPageContainer.scss';

const copyRightText = "Copyright ©2024 Animuse Health All rights reserved.";
const termsButtonText = "Terms of use";
const policyButtonText = "Privacy policy";

const LinkSeperator = () => (
    <div className='landing-page__link-seperator'>
        |
    </div>
);

const TermsAndConditions = () => (
    <div className='landing-page__terms-and-conditions'>
        <a className='landing-page__link' href={window.location.origin}>
            {termsButtonText}
        </a>
        <LinkSeperator />
        <a className='landing-page__link' href={window.location.origin}>
            {policyButtonText}
        </a>
    </div>
)

export const LandingPageContainer = ({
    showLogo = true,
    showTermsAndConditions,
    children
}) => (
    <div className="landing-page">
        <Col className="landing-page__element-container">
            {showLogo && <img className="landing-page__device-logo" src={animusePearl} alt="animuse-device" />}
            {children}
        </Col>
        {
            showTermsAndConditions && <TermsAndConditions />
        }
        <div className="landing-page__copyright-footer">
          {copyRightText}
        </div>
      </div>
);
