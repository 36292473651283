import React from "react";

const Ellipse = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="28"
      fill="none"
      viewBox="0 0 106 28"
    >
      <g filter="url(#filter0_f_546_24)" opacity="0.3">
        <ellipse cx="53" cy="14" fill="#000" rx="43" ry="4"></ellipse>
      </g>
      <defs>
        <filter
          id="filter0_f_546_24"
          width="106"
          height="28"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_546_24"
            stdDeviation="5"
          ></feGaussianBlur>
        </filter>
      </defs>
    </svg>
  );
}

export default Ellipse;
