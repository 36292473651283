import React from "react";
import Button from "../components/common/Button";

import { SitePasswordGate } from "../components/SitePasswordGate";
import { useSelector } from "react-redux";
import { selectSitePasswordValidated } from "../redux/auth/selectors";

import "./logIn.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { LandingPageContainer } from "../components/LandingPageContainer";

const LogIn = () => {
  const isSitePasswordValidated = useSelector(selectSitePasswordValidated);
  document.body.classList.remove('dark-theme');

  const {
    loginWithRedirect
  } = useAuth0();

  const handleOnLogInClicked = () => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/home`
      }
    });
  };


  return (
    <LandingPageContainer showLogo={isSitePasswordValidated}>
      {
        isSitePasswordValidated ? (
          <div className="log-in__login-in-button">
              <Button variant="primary" showBorder={true} onClick={handleOnLogInClicked}>
                log in
              </Button>
            </div>
        ) : (
          <SitePasswordGate />
        )
      }
    </LandingPageContainer>
  );
};

export default LogIn;
