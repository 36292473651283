export const ThumbPrint = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={154}
    height={157}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M77.42 75.335c-.774-.985-2.106-1.19-3.03-.466-.922.725-1.04 2.068-.267 3.053l10.02 12.77c7.068 9.006 11.288 19.678 12.146 30.886l.696 8.15c.108 1.223 1.161 2.159 2.328 2.152a2.082 2.082 0 0 0 1.205-.433c.527-.414.828-1.048.772-1.8l-.697-8.15c-.964-12.09-5.478-23.612-13.125-33.357L77.447 75.37l-.028-.035Z"
      />
      <path
        fill="url(#c)"
        d="M37.805 106.307c-5.08-6.473-8.554-13.752-10.312-21.694-.262-1.216-1.42-2.013-2.575-1.788-1.183.19-1.896 1.317-1.602 2.507 1.865 8.485 5.754 16.631 11.192 23.562l8.227 10.484 1.684 2.146c.745.95 2.138 1.164 3.029.465.923-.724 1.013-2.102.267-3.052l-1.684-2.146-8.199-10.449-.027-.035Z"
      />
      <path
        fill="url(#d)"
        d="M26.451 77.245c-1.216-16.213 5.32-31.573 17.517-41.145 12.198-9.571 29.574-12.407 45.44-6.781.717.234 1.432.127 1.96-.287.33-.258.543-.597.674-1.04.344-1.123-.341-2.404-1.476-2.764-17.2-6.171-35.678-3.381-49.358 7.354-13.681 10.736-20.369 27.125-19.061 44.743.08 1.188 1.128 2.185 2.328 2.153 1.167-.007 2.084-1.01 1.976-2.233Z"
      />
      <path
        fill="url(#e)"
        d="M98.165 28.53c-1.086-.569-2.351-.145-2.872.89-.492 1.068-.027 2.408 1.059 2.977 6.718 3.539 12.493 8.386 17.242 14.438l10.269 13.087c.773.985 2.106 1.19 3.029.465.923-.724 1.041-2.067.268-3.052l-10.27-13.087c-5.162-6.58-11.483-11.851-18.758-15.692l.033-.026Z"
      />
      <path
        fill="url(#f)"
        d="M60.396 32.531c-1.133.321-1.702 1.564-1.281 2.711.42 1.148 1.65 1.832 2.755 1.476 16.079-4.83 34.231 1.333 45.163 15.264l10.27 13.088c3.865 4.925 7.396 10.51 10.482 16.615.547 1.105 1.886 1.59 2.914 1.067.132-.104.259-.146.357-.224.759-.595 1.009-1.7.511-2.674-3.218-6.342-6.909-12.2-10.967-17.371l-10.27-13.087c-12.092-15.41-32.183-22.21-49.934-16.865Z"
      />
      <path
        fill="url(#g)"
        d="m54.667 114.22-10.27-13.087C29.933 82.699 32.216 56.692 49.49 43.136a36.656 36.656 0 0 1 4.892-3.27c1-.557 1.333-1.898.72-2.952-.614-1.053-1.958-1.476-2.959-.918-1.902 1.038-3.699 2.277-5.413 3.622C27.64 54.596 25.117 83.351 41.1 103.72l10.27 13.087c1.435 1.83 2.349 4.012 2.524 5.864l.183 2.812c.08 1.188 1.095 2.211 2.295 2.179.49.013.902-.14 1.265-.425.494-.388.829-1.048.766-1.738l-.177-2.874c-.216-2.786-1.511-5.862-3.499-8.395l-.06-.01Z"
      />
      <path
        fill="url(#h)"
        d="M106.257 61.175c-.923.724-1.041 2.068-.268 3.053l4.693 5.98c6.35 8.092 11.641 18.093 15.328 28.9.388 1.173 1.617 1.857 2.722 1.501.253-.085.479-.205.677-.36.659-.517.954-1.43.666-2.341-3.826-11.323-9.41-21.834-16.063-30.313l-4.693-5.98c-.773-.985-2.106-1.19-3.029-.466l-.033.026Z"
      />
      <path
        fill="url(#i)"
        d="M52.25 46.654c-4.945 3.88-8.72 9.117-10.902 15.092-.404 1.113.188 2.411 1.284 2.858 1.124.482 2.367-.038 2.739-1.126 1.936-5.27 5.228-9.9 9.612-13.34 13.648-10.71 34-7.582 45.43 6.983.773.985 2.105 1.19 3.028.466.923-.725 1.041-2.068.268-3.053-12.947-16.5-36.025-20.047-51.487-7.915l.028.035Z"
      />
      <path
        fill="url(#j)"
        d="M41.658 69.064c-1.179-.212-2.234.616-2.341 1.837-.863 9.828 2.11 19.66 8.378 27.645l5.107 6.509c.773.985 2.105 1.19 3.028.465.924-.724 1.041-2.067.268-3.052l-5.107-6.509c-5.521-7.036-8.167-15.703-7.373-24.397.134-1.186-.749-2.312-1.96-2.498Z"
      />
      <path
        fill="url(#k)"
        d="M61.26 109.047c-.772-.985-2.105-1.19-3.028-.466s-1.04 2.067-.268 3.052c2.65 3.378 4.217 7.207 4.496 11.024l.412 6.499c.08 1.187 1.095 2.21 2.295 2.178.49.013.902-.14 1.265-.425.495-.388.829-1.048.766-1.738l-.406-6.559c-.408-4.796-2.296-9.51-5.498-13.591l-.033.026Z"
      />
      <path
        fill="url(#l)"
        d="M112.736 84.57c-1.028.522-1.415 1.793-.807 2.907 3.738 7.071 6.723 15.017 8.603 22.978.294 1.19 1.452 1.987 2.608 1.762.341-.04.632-.212.863-.393.593-.466.911-1.283.711-2.149-1.969-8.346-5.104-16.686-9.03-24.065-.581-1.08-1.892-1.528-2.92-1.006l-.028-.035Z"
      />
      <path
        fill="url(#m)"
        d="M108.077 81.007c.646 1.027 1.996 1.39 3.001.77l.165-.129c.824-.647 1.025-1.884.406-2.876a86.396 86.396 0 0 0-4.236-5.942l-10.27-13.087a30.746 30.746 0 0 0-9.338-7.963c-1.085-.57-2.378-.18-2.866.828-.52 1.034-.054 2.373.998 2.968 3.076 1.68 5.701 3.94 7.91 6.754l10.27 13.087a75.621 75.621 0 0 1 3.96 5.59Z"
      />
      <path
        fill="url(#n)"
        d="M57.771 53.69c-9.428 7.399-12.59 20.34-7.828 32.178.454 1.121 1.722 1.718 2.827 1.362.22-.059.385-.188.55-.317.692-.543.965-1.553.616-2.473-4.03-10.025-1.354-20.936 6.624-27.196 5.274-4.14 12.218-5.609 19.02-4.068a2.025 2.025 0 0 0 2.478-1.66c.195-1.177-.622-2.355-1.795-2.628-8.036-1.823-16.228-.113-22.459 4.777l-.033.026Z"
      />
      <path
        fill="url(#o)"
        d="m67.854 103.873-10.27-13.087c-.773-.985-2.105-1.19-3.028-.466-.923.725-1.041 2.068-.268 3.053l10.27 13.087c3.781 4.819 6.019 10.454 6.495 16.219l.538 8.9c.08 1.188 1.096 2.21 2.296 2.178.49.013.902-.14 1.264-.424.495-.388.83-1.049.767-1.739l-.566-8.935c-.545-6.735-3.109-13.192-7.498-18.786Z"
      />
      <path
        fill="url(#p)"
        d="M96.707 72.762c-.773-.985-2.106-1.19-3.03-.465-.922.724-1.04 2.067-.267 3.052l4.113 5.242c8.061 10.273 13.896 24.225 15.972 38.342.201 1.206 1.26 2.08 2.465 1.987a1.934 1.934 0 0 0 1.051-.426c.56-.44.839-1.17.755-1.957-2.226-14.851-8.388-29.627-16.946-40.533l-4.113-5.242Z"
      />
      <path
        fill="url(#q)"
        d="M91.525 69.552c.923-.724 1.04-2.067.268-3.052l-1.243-1.583c-6.846-8.725-19.082-10.606-27.257-4.19-.923.724-1.041 2.067-.268 3.052.773.985 2.105 1.19 3.028.466 6.363-4.993 15.873-3.531 21.2 3.259l1.243 1.583c.773.985 2.106 1.19 3.029.465Z"
      />
      <path
        fill="url(#r)"
        d="M61.307 65.866c-1.053-.595-2.373-.24-2.894.793-3.479 6.766-2.474 15.242 2.468 21.54l10.27 13.087a41.236 41.236 0 0 1 5.759 9.919c.454 1.121 1.722 1.718 2.8 1.327.22-.059.384-.188.55-.317.691-.544.964-1.554.615-2.473-1.617-3.961-3.745-7.692-6.422-11.104l-10.27-13.087c-3.865-4.925-4.607-11.505-1.906-16.751.52-1.034.116-2.365-.965-2.995l-.005.061Z"
      />
      <path
        fill="url(#s)"
        d="M83.089 117.043c-.262-1.216-1.42-2.013-2.542-1.814-1.183.189-1.896 1.317-1.629 2.472.345 1.662.597 3.34.727 5l.617 9.066c.08 1.188 1.128 2.184 2.295 2.178a2.088 2.088 0 0 0 1.204-.434c.528-.413.829-1.048.772-1.799l-.644-9.101c-.142-1.878-.405-3.775-.828-5.603l.028.035Z"
      />
      <path
        fill="url(#t)"
        d="M103.155 103.855c-1.133.321-1.73 1.528-1.342 2.701 1.968 5.903 3.265 12.104 3.703 17.956.08 1.188 1.128 2.184 2.328 2.152a2.085 2.085 0 0 0 1.204-.433c.528-.414.829-1.048.772-1.8-.499-6.202-1.852-12.814-3.942-19.075-.388-1.173-1.618-1.857-2.723-1.501Z"
      />
      <path
        fill="url(#u)"
        d="M98.96 99.188c.515 1.131 1.788 1.667 2.871 1.215.159-.068.319-.137.484-.266.725-.57.97-1.614.561-2.543-2.446-5.357-5.337-10.195-8.622-14.381l-10.27-13.087c-3.809-4.855-10.593-5.898-15.142-2.328-4.55 3.57-5.15 10.407-1.34 15.262l3.948 5.031c.772.985 2.105 1.19 3.028.466.923-.725 1.041-2.068.268-3.053l-3.948-5.03c-2.291-2.92-1.932-7.01.804-9.158 2.736-2.147 6.795-1.523 9.086 1.397L90.958 85.8c3.064 3.905 5.767 8.435 8.03 13.423l-.028-.035Z"
      />
      <path
        fill="url(#v)"
        d="m81.04 93.525-1.16-1.477c-.772-.985-2.105-1.19-3.028-.466s-1.04 2.068-.268 3.053l1.16 1.477c6.1 7.775 9.712 16.992 10.467 26.575l.498 8.306c.08 1.188 1.095 2.21 2.295 2.178.49.013.903-.14 1.265-.424.495-.388.83-1.049.767-1.739l-.526-8.341c-.824-10.552-4.789-20.628-11.47-29.142Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={68.736}
        x2={106.171}
        y1={79.306}
        y2={127.011}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={21.854}
        x2={49.618}
        y1={84.904}
        y2={120.286}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={41.499}
        x2={58.891}
        y1={32.354}
        y2={54.517}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="e"
        x1={97.778}
        x2={124.982}
        y1={27.218}
        y2={61.886}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="f"
        x1={70.562}
        x2={121.861}
        y1={24.37}
        y2={89.744}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="g"
        x1={31.249}
        x2={77.959}
        y1={51.766}
        y2={111.291}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="h"
        x1={103.943}
        x2={131.743}
        y1={62.991}
        y2={98.417}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="i"
        x1={58.785}
        x2={83.63}
        y1={41.47}
        y2={73.132}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="j"
        x1={36.2}
        x2={59.72}
        y1={72.494}
        y2={102.468}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="k"
        x1={55.353}
        x2={69.322}
        y1={110.84}
        y2={128.642}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="l"
        x1={109.429}
        x2={126.983}
        y1={87.116}
        y2={109.486}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="m"
        x1={86.383}
        x2={110.629}
        y1={51.233}
        y2={82.13}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="n"
        x1={55.828}
        x2={70.266}
        y1={55.216}
        y2={73.615}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="o"
        x1={50.474}
        x2={79.212}
        y1={93.523}
        y2={130.146}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="p"
        x1={89.192}
        x2={121.492}
        y1={75.817}
        y2={116.978}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="q"
        x1={69.886}
        x2={84.931}
        y1={55.553}
        y2={74.726}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="r"
        x1={54.343}
        x2={84.906}
        y1={69.62}
        y2={108.568}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="s"
        x1={76.571}
        x2={86.833}
        y1={118.023}
        y2={131.101}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="t"
        x1={99.156}
        x2={112.362}
        y1={106.801}
        y2={123.63}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="u"
        x1={68.842}
        x2={97.304}
        y1={67.798}
        y2={104.069}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="v"
        x1={71.616}
        x2={97.484}
        y1={95.691}
        y2={128.655}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADADAD" stopOpacity={0.74} />
        <stop offset={1} stopColor="#8F8F8F" stopOpacity={0} />
      </linearGradient>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M0 64.918 82.415.246l71.583 91.223-82.414 64.672z"
        />
      </clipPath>
    </defs>
  </svg>
);
